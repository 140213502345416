import i18next from "i18next";
import i18nextHttpBackend from "i18next-http-backend";
i18next.use(i18nextHttpBackend).init({
    fallbackLng: "en",
    ns: ["common", "chat", "news"],
    defaultNS: "common",
    debug: false,
    load: "languageOnly",
    interpolation: {
        escapeValue: false,
    },
    backend: {
        loadPath: function () {
            var url = "";
            if (process.env.STAGE === "dev" || process.env.STAGE === "") {
                url = "http://localhost:9091/locales/{{lng}}/{{ns}}.json";
            }
            if (process.env.STAGE === "stage") {
                url = "https://plannord.nyc3.digitaloceanspaces.com/plannord/static/locales/{{lng}}/{{ns}}.json";
            }
            if (process.env.STAGE === "prod") {
                url = "https://plannord-prod.nyc3.digitaloceanspaces.com/plannord-prod/static/locales/{{lng}}/{{ns}}.json";
            }
            return url;
        },
    },
    react: { wait: true, useSuspense: true },
}, function (err) {
    if (err)
        console.log("i18n init error:", err);
});
export default i18next;
